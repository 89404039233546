<template>
	<div class="grid justify-content-center">
		<div class="col-12">
			<Toast/>
		</div>
		<div class="col-8" v-if="!questionnarieLoad && !successfull">
			<div class="card">
				<h5 class="text-center">Kérdőív rendszer</h5>
				<p class="text-center">Köszönjük, hogy felkereste az oldalt!</p>
				<div v-if="infoid && !message">
					<p>ID: {{ infoid }}</p>
				</div>
				<div v-else>
					<div class="grid formgrid justify-content-center">
						<div class="card">
							<h6 class="text-center">Kérjük írja be a kérdőív kódját</h6>
							<div class="col-12 text-center">
								<span class="p-input-icon-left p-input-icon-right">
									<i class="pi pi-question-circle" v-on:click="InfoSubmit()" />
									<InputText v-model="infoidtemp" type="text" placeholder="Keresés" @keyup.enter="InfoSubmit()" />
									<i class="pi pi-search" v-on:click="InfoSubmit()"/>
								</span>
							</div>
							<div class="col-12 mt-4 mb-2 text-center" v-if="message">
								<span>Kérjünk adjon meg másik kérdőív kódot, mivel jelenleg ez a kérdőív nem elérhető!</span>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div v-if="loading" style="position: absolute; top:0; left: 0; width: 100%; height: 100%; background: rgba(76, 175, 80, 0.3);">
			<div class="splash-screen">
				<div class="splash-loader-container">
					<svg class="splash-loader" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
						<circle class="splash-path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
					</svg>
				</div>
			</div>
		</div>

		<div class="col-6" v-if="questionnarieLoad">
			<div class="card justify-content-center">
				<h5>Kérdőív: {{ questionnarie.name }}</h5>
				<p>{{ questionnarie.description }}</p>
				<div class="p-fluid">
					<div class="field" v-for="q in questionnarie.questionnairequestions" :key="q.id">
						<div v-if="q.questionariegroup == 0">
							<div class="repeat" v-for="num in getRepeat(q.repeatnumber)" :key="num">
								<QFields v-model="answer" :question="q" :number="num" :lists="lists" 
									:groupid="0" :id="getId(num, q.id, q.questionariegroup)"
									:questions="questionnarie.questionnairequestions"
									:groupinfo="getGroup(q.questionariegroup)"
									:submitted="submitted"
								/>
							</div>
							<div v-if="q.repeatnumber >= 1 && q.repeatmore == true">
								<Button label="Plusz elem" class="p-button-text" @click="MoreElement(q)"/>
							</div>
						</div>
					</div>
					<Button label="Beküldés" class="p-button-text" @click="addQuestion()" />
				</div>
			</div>
		</div>		

		<div class="col-10" v-if="successfull">
			<div class="card justify-content-center">
				<h5>Kérdőív: {{ questionnarie.name }}</h5>
				<p>Köszönjük, hogy kitöltötte a kérdőívet!</p>

				<div>
					<Button label="Új kérdőÍv" class="p-button-text" @click="newQuestion()" />
					<Button label="Kérdőív újabb kiöltése" class="p-button-text" @click="newQuestion2()" />
					<Button label="Kérdőív újabb kiöltése adatok megtartásával" class="p-button-text" @click="newQuestion3()" />

				</div>
			</div>
		</div>

	</div>
</template>

<script>

import QuestionnarieService from '../service/QuestionnarieService.js';
import QFields from '../components/QFields.vue'

export default {
	data() {
		return {
			infoid: this.$route.query.link,
			infoidtemp : null,
			loading: true,
			infotrue: false,
			message: "",
			questionnarie: null,
			questionnarieLoad: false,
			successfull: false,
			answer: {},
			submitted: false,
			lists: {},
			valid: true,
		}
	},
	mounted() {
		if (this.infoid && this.infoid.length > 0) {
			this.infoidtemp = this.infoid;
			this.loadInfo(this.infoid)

		} else {
			this.loading = false;
		}
	},
	created() {
		this.questionnarieService = new QuestionnarieService();
	},
	methods: {
		MoreElement(q) {
			q.repeatnumber++;
		},
		loadInfo(link) {
			this.questionnarieService.getQuestion(link).then(data => {
				// console.log ("DATA-INFO", data)

				if (data.code != "200") {
					this.message = "Hibás kérdőÍvkód!";
				} else {
					this.questionnarie = data.Q;
					this.lists = data.lists;
					this.questionnarieLoad = true;
					this.questionnarie.questionnairequestions.forEach(function(item) {
						item.maxrepeatnumber = 10
						if (item.repeatnumber == -1) {
							item.repeatnumber = 1
							item.repeatmore = true
						} else {
							item.repeatmore = false
						}
					})
					this.answer = {};
					this.answer['valid'] = true;
					this.answer['link'] = link;
				}
				this.loading = false;
			});
			return false
		},
		InfoID(name, name2) {
			return name + "_" + name2
		},
		InfoSubmit() {
			this.loading = true;
			this.loadInfo(this.infoidtemp)

		},
		addQuestion() {
			this.submitted = true
			var valid = true
			Object.keys(this.answer).forEach(key => {
				if (key.endsWith("_valid")) {
					valid = valid && this.answer[key];
				}
			});
			
			if (valid) {
				this.loading = true;
				var temp_question = this.answer
				this.questionnarieService.addQuestion(temp_question).then(data => {
					// console.log ("Sikerült!", data)
					if (data.code == "200") {
						this.loading = false;
						this.questionnarieLoad = false;
						this.successfull = true;
					} else {
						// Ki kell dolozni!
						console.log ("ERROR - 200 code")
					}
				});
			} else {
				this.$toast.add({severity:'error', summary: 'Kitöltési hiba', detail: 'A kitöltés nem teljes. Kérjük a piros mezőket töltse ki!', life: 5000});
				window.scroll({
					top: 0,
					left: 0,
					behavior: 'smooth'
				})
			}
		},
		getId (num, id, groupid) {
			return 'a_'+id+this.getGroup(groupid) + '_' + num
		},
		getGroup (groupid) {
			return '_g_'+groupid+'_1'
		},
		newQuestion() {
			this.loading = false;
			this.questionnarieLoad = false;
			this.successfull = false;
			this.infoid = null;
			this.questionnarie = {}
			this.lists = {};
			console.log ("A1")
			this.answer = {};			
			this.submitted = false;
			this.infoidtemp = "";
		},
		newQuestion2() {
			console.log ("A2")
			this.answer = {};
			this.loading = false;
			this.questionnarieLoad = true;
			this.successfull = false;
			this.submitted = false;
		},
		newQuestion3() {
			this.loading = false;
			this.questionnarieLoad = true;
			this.successfull = false;
			this.submitted = false;
		},
		getRepeat(repeatnumber) {
			if (repeatnumber > 0) {
				return repeatnumber
			} else {
				return 1;
			}
		}		
	},
	components: {
		QFields,
	}
}
</script>

<style scoped>

</style>
