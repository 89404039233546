<template>
    <div v-if="question && question.questionariegroup == groupid"> 
        <!-- ANSWER: {{ answer[id+'_valid']}} {{ getInfo() }} -->
        <div v-if="question.questiontypeid == '1'">
            <label v-if="number == 1">{{ question.rank }}. {{ question.name }}</label>
        </div>
        <div v-else-if="question.questiontypeid == '2'">
            <label v-if="number == 1" :for="id" :class="{'p-error':submitted && !getInfo()}" >{{ question.rank }}. {{ question.name }} <span v-if="question.required">*</span></label>
            <InputText :id="id" :name="id" v-model="value" @input="nameChanged($event)" :required="question.required" :class="{'p-invalid': submitted && !getInfo()}" />
			<small class="p-invalid" v-if="submitted && !getInfo()">A mező kitöltése kötelező!</small>
        </div>
        <div v-else-if="question.questiontypeid == '3'">
            <label v-if="number == 1" :for="id" :class="{'p-error':submitted && !getInfo()}">{{ question.rank }}. {{ question.name }} <span v-if="question.required">*</span></label>
            <Textarea :id="id" :name="id" v-model="value" @input="nameChanged($event)" :required="question.required" rows="5" cols="20" />
            <small class="p-invalid" v-if="submitted && !getInfo()">A mező kitöltése kötelező!</small>
        </div>
        <div v-else-if="question.questiontypeid == '6'">
            <label v-if="number == 1" :for="id" :class="{'p-error':submitted && !getInfo()}">{{ question.rank }}. {{ question.name }} <span v-if="question.required">*</span></label>
            <Calendar 
                dateFormat="yy-mm-dd"
                :showIcon="true"
                :showWeek="true"
                :id="id" :name="id" v-model="value" @input="nameChanged($event)"
                :required="question.required"
            />
            <small class="p-invalid" v-if="submitted && !getInfo()">A mező kitöltése kötelező!</small>
        </div>
        <div v-else-if="question.questiontypeid == '7'">
            <label v-if="number == 1" :for="id" :class="{'p-error':submitted && !getInfo()}">{{ question.rank }}. {{ question.name }} <span v-if="question.required">*</span></label>
            <InputNumber :id="id" :name="id" v-model="value" @input="nameChanged($event)" mode="currency" currency="HUF" locale="hu-HU" 
                :maxFractionDigits="0" :required="question.required"
            
            />
            <small class="p-invalid" v-if="submitted && !getInfo()">A mező kitöltése kötelező!</small>            
        </div>

        <div v-else-if="question.questiontypeid == '14'">
            <label v-if="number == 1" :for="id" :class="{'p-error':submitted && !getInfo()}">{{ question.rank }}. {{ question.name }} <span v-if="question.required">*</span></label>
            <Dropdown :id="id" :name="id" v-model="value" @input="nameChanged($event)" :options="list" optionLabel="name" placeholder="Kérem válasszon" style="height: 3rem;"
                :filter="true" :showClear="true"
            
            >
                <template #value="slotProps">
                    <div v-if="slotProps.value && slotProps.value.name">
                        <span>{{slotProps.value.name}}</span>
                    </div>
                    <span v-else>
                        {{slotProps.placeholder}}
                    </span>
                </template>
            </Dropdown>
            <small class="p-invalid" v-if="submitted && !getInfo()">A mező kitöltése kötelező!</small>            
        </div>

        <div v-else-if="question.questiontypeid == '17'">
            <div class="p-float-label p-input-icon-right" :class="{'p-float-label-error':submitted && !getInfo()}">
                <i class="pi pi-user" />
                <InputText :id="id" :name="id" v-model="value" @input="nameChanged($event)" :required="question.required" />
                <label :for="id" :class="{'p-error':submitted && !getInfo()}">Név <span v-if="question.required">*</span></label>
                <small class="p-invalid" v-if="submitted && !getInfo()">A mező kitöltése kötelező!</small>
            </div>
        </div>

        <div v-else-if="question.questiontypeid == '16'">
            <div class="p-float-label p-input-icon-right" :class="{'p-float-label-error':submitted && !getInfo()}">
                <i class="pi pi-envelope" />
                <InputText :id="id" :name="id" v-model="value" @input="nameChanged($event)" :class="{'p-invalid':submitted}" aria-describedby="email-error" :required="question.required"/>
                <label :for="id" :class="{'p-error':submitted && !getInfo()}">Email cím <span v-if="question.required">*</span></label>
                <small class="p-invalid" v-if="submitted && !getInfo()">A mező kitöltése kötelező!</small>
            </div>					
        </div>

        <div v-else-if="question.questiontypeid == '15'" :class="{'p-float-label-error':submitted && !getInfo()}">
            <div class="p-float-label p-input-icon-right">
                <i class="pi pi-phone" />
                <InputMask :id="id" :name="id" v-model="value" @input="nameChanged($event)" mask="99-99-9999999" :required="question.required" />
                <label :for="id" :class="{'p-error':submitted && !getInfo()}">Telefonszám <span v-if="question.required">*</span></label>
                <small class="p-invalid" v-if="submitted && !getInfo()">A mező kitöltése kötelező!</small>
            </div>					
        </div>

        <div v-else-if="question.questiontypeid == '18'">
            <div class="mb-3">
                <label v-if="number == 1" :for="id" :class="{'p-error':submitted && !getInfo()}">{{ question.rank }}. {{ question.name }} <span v-if="question.required">*</span></label>
            </div>
            <div class="grid">
                <div class="col-12 md:col-4">
                    <div class="field-radiobutton">
                        <RadioButton :id="id+'_1'" :name="id" v-model="value" @input="nameChanged($event)" value="Yes" />
                        <label :for="id+'_1'" :class="{'p-error':submitted && !getInfo()}">Igen</label>
                    </div>
                </div>
                <div class="col-12 md:col-4">
                    <div class="field-radiobutton">
                        <RadioButton :id="id+'_2'" :name="id" v-model="value" @input="nameChanged($event)" value="No" />
                        <label :for="id+'_2'" :class="{'p-error':submitted && !getInfo()}">Nem</label>
                    </div>
                </div>
            </div>
            <small class="p-invalid" v-if="submitted && !getInfo()">A mező kitöltése kötelező!</small>
        </div>
        <div v-else-if="question.questiontypeid == '19'">
            <label v-if="number == 1" :for="id" :class="{'p-error':submitted && !getInfo()}">{{ question.rank }}. {{ question.name }} <span v-if="question.required">*</span></label>
            <InputText :id="id" :name="id" v-model="value" @input="nameChanged($event)" :required="question.required" />
            <Slider :id="id+'_s'" :name="id+'_s'" v-model="value" @input="nameChanged($event)" />
            <small class="p-invalid" v-if="submitted && !getInfo()">A mező kitöltése kötelező!</small>
        </div>

        <div v-else>
            <label v-if="number == 1" class="p-error">N - {{ question.questiontypeid }} <span v-if="question.required">*</span></label>
        </div>

        <div v-if="checkInfo(question.id)">
            <div class="field" v-for="q in questions" :key="q.id">
                <div v-if="q.questionariegroup == question.id">
                    <ul style="list-style-type: none; padding-left: 1rem;">
                        <li class="repeat" v-for="num in getRepeat(q.repeatnumber)" :key="num">
                                <QFields v-model="answer" :question="q" :number="num" :lists="lists" 
                                    :groupid="question.id" :questions="questions" :repeatinfo="num"
                                    :id="getId(num, q.id, q.questionariegroup)"
                                    :groupinfo="getGroup(groupinfo)"
                                    :submitted="submitted"
                                />
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    props: ["question", "modelValue", "number", "lists", "groupid", "questions", "repeatinfo", "id", "groupinfo", "submitted"],
    data () {
        return {
            value: "",
            list: {},
            answer: {},
        }
    },
    mounted() {
        // console.log("Mounted START" )
        this.answer[this.id+'_valid'] = this.getInfo()
        this.answer[this.id] = this.value;
        this.$emit("update:modelValue", this.answer)
        // console.log("Mounted END" )
    }, 
    created() {
        // console.log("Created START" )

        if (this.question.questiontypeid == 7 || this.question.questiontypeid == 19) {
            if (this.modelValue && this.modelValue[this.id]) {
                this.value = Number(this.modelValue[this.id]);
            } else {
                this.value = Number(this.question.defaultvalue);
            }
        } else if (this.question.questiontypeid == 6) {
            this.value = "2022-08-24";
        } else {
            // console.log ("DD - A", this.modelValue, this.modelValue[this.id])
            if (this.modelValue && this.modelValue[this.id]) {
                this.value = this.modelValue[this.id];
            } else {
                // console.log ("DF S")
                this.value = this.question.defaultvalue;
            }
        }


        this.answer = this.modelValue
        this.lists.forEach(item => {
            if (item.id == this.question.list) {
                this.list = item.list
            }
        });
        this.answer[this.id+'_valid'] = this.getInfo()
        this.answer[this.id] = this.value;

        /*
        if (this.question.id == 6) {
            console.log ("Type:", this.question.questiontypeid, "ID", this.question.id, this.answer, this.modelValue, this.value, this.answer[this.id], this.id)
        }
        */

        this.$emit("update:modelValue", this.answer)
        // console.log("Created END" )
    },
    watch: {
        value (newValue) {
            this.answer[this.id] = newValue
            this.answer[this.id+'_valid'] = this.getInfo()
            this.$emit("update:modelValue", this.answer)
        }
    },
    methods: {
        nameChanged ($event) {
            this.answer[this.id] = $event.value
            this.answer[this.id+'_valid'] = this.getInfo()
            this.$emit("update:modelValue", this.answer)
        },
        checkInfo (id) {
            var volt = false
            this.questions.forEach(item => {
                if (item.questionariegroup == id) {
                    volt = true
                }
            })
            return volt
        },
        getNum(num) {
            return this.question.questionariegroup + "_" + num;
        },
		getId (num, id, groupid) {
			return 'a_'+id+this.getGroup(groupid) + '_' + num
		},
		getGroup (groupid) {
			return this.groupinfo + '_g_'+groupid+'_' + this.number
		},
        getInfo() {
            if (! this.question.required) {
                return true;
            }
            if (this.answer && typeof (this.answer[this.id]) !== 'undefined' && this.answer[this.id] && this.answer[this.id].toString().length > 0) {
                return true;
            }
            return false;
        },
		getRepeat(repeatnumber) {
			if (repeatnumber > 0) {
				return repeatnumber
			} else {
				return 1;
			}
		}        
    } 
}

</script>

